.enterTxt-enter{
    //opacity: 0;
	top: 200px;
    position: relative;
}
.enterTxt2-enter{
    //opacity: 0;
	top: 200px;
    position: relative;
}

  .enterTxt-enter-done {
	//opacity: 1 !important;
	top: 0px !important;
	transition: all 500ms;

  }
  .enterTxt2-enter-done {
	//opacity: 1 !important;
	top: 0px !important;
	transition: all 500ms;

  }

.text-edit{
  font-size: 22px;
  color: #fff;
}