// $primary: #3F51B5;
// $dark-primary: #303F9F;
// $light-primary: #9F3583;
// $text: #FFFFFF;
// $primary-text: #212121;
// $secondary-text: #757575;
// $accent: #FF4081;

section {
}

html, body {
  overflow-x: hidden;
}

.timelineContainerItem{
	width: 30%;
}

.timeLineMarginLeft{
	margin-left: 15%;
}

.timeline-item {
	width: 100%;
	margin-bottom: 100px;

	&:nth-child(even) {

		.timeline-content {
			float: right;
			.date {
				right: auto;
				left: 0;
			}			

			&:nth-child(odd){
				:hover{					

				h2{
					border: none;
					box-shadow:none;					
				}
				p{
					border: none;
					box-shadow:none;
				}
				}
				:hover::after{
						
					border-color: transparent transparent transparent #fff;
				}
		}

		}
	}

	&:nth-child(odd) {

		.timeline-content {
			.date {
				right: auto;
				left: 0;
			}
		&:nth-child(even){
			:hover{
				::after{
					border-color: transparent transparent transparent #fff;
				}

			h2{
				border: none;
				box-shadow:none;
				
			}
			p{
				border: none;
				box-shadow:none;
			}
			}
		}

		}
	}
	
	&::after {
		content: '';
		display: block;
		clear: both;
	}
}

.content2 h3{
	color:#fff;
	font-size:17px;
}

.content2 h2{
	font-size: 25px;
}

.content2 p{
	color:#fff;
	font-size: 20px;
}
.timelinContent{
	padding: 10px;
   padding-right: 20px;
}

.timeline-content {
	position: relative;
	border-radius: 4px;
	// box-shadow: 0 20px 25px -15px rgba(0, 0, 0, .3);
}

.timeline-img {
	width: 30px;
    height: 30px;
    background: linear-gradient(180deg, var(--color-primary-light), var(--color-primary));
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-top: 25px;
    margin-left: -15px;
    z-index: 5;
}

.timeline-img:hover {
	box-shadow:0px 0px 5px 2px #fff;
}

.timeline-card {
	padding: 0!important;

	p {
		padding: 0 20px;
	}

	a {
		margin-left: 20px;
	}
}

.timeline-img-header {
	position: relative;
	margin-bottom: 0px;

	h2 {
		color: #FFFFFF;
		position: absolute;
		bottom: 5px;
		left: 20px;
	}
}

blockquote {
	margin-top: 30px;
	color: #757575;
	border-left-color: #3F51B5;
	padding: 0 20px;
}

.date {
	background: #FF4081;
	display: inline-block;
	color: #FFFFFF;
	padding: 10px;
	position: absolute;
	top: 0;
	right: 0;
}

.bullet-enter {
	opacity: 0;
  }
  .bullet-enter-done {
	opacity: 1;
	transition: all 2500ms;
  }

  .activeTimeline{
	border: solid 3px #9F3583;
    box-shadow: 0px 0px 10px 0px #b100ff;
}

.activeBullet{
	background: #ffb613;
    box-shadow: 0px 0px 10px 6px #ffb613;
}

.timelineContainer{
	display: flex;
	justify-content: center;
}


.timlineSylingRight{
    border-width: 3px 3px 3px 3px;
    border-color: #6bb8e9;
    box-shadow: 0px 0px 10px 0px #083959;
	margin: 19px 48px 42px 0px;
    border-radius: 50px 50px 50px 50px;
    padding: 31px;
    width: 153%;
    margin-left: -67%;

}

.timlineSylingLeft{
    border-width: 3px 3px 3px 3px;
    border-color: #2b141b;
    box-shadow: 0px 0px 10px 0px #61273a;
    // transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 19px 48px 42px 0px;
    border-radius: 50px 50px 50px 50px;
    padding: 31px;
    width: 153%;
    margin-left: 18%;
}

.timelineImageRight{
   margin-left: 45%;	
}

.timelineImageLeft{
   margin-left: -45%;	
}


@media screen and (max-width: 768px) {

	.timlineSylingRight{
		border-width : medium;
		border-color : inherit;
		box-shadow : none;
		margin : 0;
		border-radius: unset;
		width: unset;
		margin-left : 0;
	}
	.timlineSylingLeft{
		border-width : medium;
		border-color : inherit;
		box-shadow : none;
		margin : 0;
		border-radius: unset;
		width: unset;
		margin-left : 0;
	}
	.timelineImageRight{
	   margin-left: 0%;	
	}

	.timelineImageLeft{
		margin-left: 0%;	
	 }

	 .timelinContent {
		padding: 10px 20px 10px 10px;
	}

	.timelineContainer{
		display: block;
	}

	.timeLineMarginLeft{
		margin-left: 0;
	}

	.timelineContainerItem{
		width: 100%;
	}

	.imageItem{
		display: none;
	}

	.timeline2 {

		&::before {
			left: 50px !important;
		}

		.timeline-img {
			left: 50px;
		}

		.timeline-content {
			max-width: 100%;
			width: auto;
			margin-left: 70px;

			.picture{
				display: none;
			}
		}

		.timeline-item {

			.alertasss-enter-done{
				left: 0px;
			}
			margin-bottom: 20px;
			&:nth-child(even) {

				.timeline-content {
					float: none;

				}
			}

			&:nth-child(odd) {

				.timeline-content {
					float: none;					
				}
			}
		}
	}

}
